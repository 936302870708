import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
{/* With this causes an error on build =>  from '@react-pdf/renderer'; */}
import { Page, Text, Image, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink } from '@react-pdf/renderer/lib/react-pdf.browser.es.js';
import { Layout } from '@components';
import img from '@images/file-pattern.png';
import Html from 'react-pdf-html';
import reactToCSS from 'react-style-object-to-css';


const styles = StyleSheet.create({
  PDFViewer: {
    width: '100%',
    height: '100vh',
  },
  body: {
    paddingTop: 28,
    paddingBottom: 28,
    paddingHorizontal: 48,
  },
  title: {
    fontSize: 30,
    fontFamily: 'Montserrat',
    color: '#C00000',
  },
  role: {
    fontSize: 15,
    color: '#4F4F4F',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Montserrat',
  },
  h3: {
    fontSize: 9,
    color: '#4F4F4F',
    fontFamily: 'Helvetica-Bold',
    padding: '4px 0',
  },
  text: {
    lineHeight: '1.5px',
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
    color: '#4F4F4F',
  },
  image: {
    position: 'absolute',
    top: '-2%',
    left: 0,
    width: '100vw',
    zIndex: '-1',
    opacity: '.2',
  },
  header: {
    fontSize: 9,
    marginBottom: 15,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    flexDirection: 'row',
  },
  left: {
    width: '70%',
    paddingRight: '2%',
  },
  right: {
    width: '30%',
    paddingLeft: '2%',
  },
  section: {
    padding: '10px 0',
  },
  subtitleSection: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: '#C00000',
    borderBottom: '1px solid #C00000',
    marginBottom: '10px',
    paddingBottom: '5px',
  },
  bullet: {
    marginLeft: '15px',
  },
});

Font.register({
  family: 'Montserrat',
  src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Hw2aXpsog.woff2',
});

const MyDocument = ({ jobs, education, projects, skills }) => (
  <Document>
    <Page style={styles.body}
      author={'Joao Silva'}
      subject={'Resume'}
      title={'Joao Silva - Resume'}
    >
      <Image
        fixed
        cache
        style={styles.image}
        src={img}
      />
      <Text style={styles.header} fixed>
        Check out my ecommerce store at &nbsp;
        <Html>
          {`<a href="https://fyke.store" style="${ reactToCSS(styles.header) }">fyke.store</a>`}
        </Html>
        &nbsp;and projects at&nbsp;
        <Html>
          {`<a href="https://fyke.cc" style="${ reactToCSS(styles.header) }">fyke.cc</a>`}
        </Html>
      </Text>

      <View style={[styles.row, { textAlign: 'left' /*, borderBottom: '1px dotted #dcdcdc'*/, paddingBottom: 10, marginBottom: 5 }]}>
        <View style={styles.left}>
          <Text style={styles.title}>Joao Silva</Text>
          <Text style={styles.role}>FullStack Developer</Text>
        </View>

        {/* CONTACT */}
        <View style={styles.right}>
          <Text style={styles.text}>pepar_jp33@hotmail.com</Text>
          <Text style={styles.text}>(+351) 964 878 243</Text>
          <Text style={styles.text}>linkdin.com/in/joaosl</Text>
          <Text style={styles.text}>github.com/p3p4r</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.left}>
          {/* PROFESSIONAL SUMMARY */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>PROFESSIONAL SUMMARY</Text>
            <Text style={styles.text}>
              Software engineering lead with more than {new Date().getFullYear() - 2020}+ years of experience, developing innovative software solutions and applications for enterprise customers.
              Adept at identifying opportunities and challenges to enhance the experience by developing new features.
            </Text>
          </View>

          {/* EXPERIENCE */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>EXPERIENCE</Text>

            {jobs.slice(0, 4).map(({ node }) => {
              const { company, title, range } = node.frontmatter;
              const { html } = node;
              let desc = html;
              desc = desc.match(/<[^>]+>[^<]*<\/[^>]+>/g);
              return (
                <>
                  <View key={node} style={{ paddingBottom: '10px' }}>
                    <View style={styles.row}>
                      <View style={styles.left}>
                        <Text style={[styles.h3, { padding: 0 }]}>{title}</Text>
                      </View>

                      <View style={styles.right}>
                        <Text style={[styles.text, { textAlign: 'right' }]}>{range}</Text>
                      </View>
                    </View>

                    <Text style={styles.text}>{company}</Text>
                    <View style={styles.text}>
                      {desc.map(function(item){
                        return  <>
                          <Text key={item} style={[styles.text, styles.bullet]}>
                            {'\u2022'} {item.replace(/<(\/*)li[^>]*>/g, '')}
                          </Text>
                        </>;
                      })
                      }
                    </View>
                  </View>
                </>
              );
            })}
          </View>
        </View>

        <View style={[styles.right, { borderLeft: '1px dotted #dcdcdc' }]}>

          {/* SKILLS */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>SKILLS</Text>

            {skills.map(({ node }, i) => {
              const { programing, tools, plataforms, others } = node.frontmatter;
              return (
                <>
                  <View key={i}>
                    {programing ? (
                      <>
                        <Text style={styles.h3}>Programming languages</Text>
                        <Text style={[styles.text, { textAlign: 'left' }]}>
                          {programing.toString().replaceAll(',', ', ')}
                        </Text>
                      </>
                    ) : <Text />}

                    {tools ? (
                      <>
                        <Text style={styles.h3}>Libraries & Frameworks</Text>
                        <Text style={[styles.text, { textAlign: 'left' }]}>
                          {tools.toString().replaceAll(',', ', ')}
                        </Text>
                      </>
                    ) : <Text />}

                    {plataforms ? (
                      <>
                        <Text style={styles.h3}>Tools & Platforms</Text>
                        <Text style={[styles.text, { textAlign: 'left' }]}>
                          {plataforms.toString().replaceAll(',', ', ')}
                        </Text>
                      </>
                    ) : <Text />}


                    {others ? (
                      <>
                        <Text style={styles.h3}>Others</Text>
                        <Text style={[styles.text, { textAlign: 'left' }]}>
                          {others.toString().replaceAll(',', ', ')}
                        </Text>
                      </>
                    ) : <Text />}
                  </View>
                </>
              );
            })}
          </View>

          {/* PERSONAL PROJECTS */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>PERSONAL PROJECTS</Text>

            {projects.map(({ node }) => {
              const { title, tech, external } = node.frontmatter;
              return (
                <>
                  <Html>
                    {`<a href="${  external  }" style="${ reactToCSS(styles.h3) }">${  title  }
                    </a>`}
                  </Html>
                  <Text style={[styles.text, { textAlign: 'left' }]}>
                    {tech.toString().replaceAll(',', ', ')}
                  </Text>

                </>
              );
            })}
          </View>

          {/* EDUCATION */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>EDUCATION</Text>
            {education.map(({ node }) => {
              const { title, school, range } = node.frontmatter;
              return (
                <>
                  <Text style={styles.h3}>{title}</Text>
                  <Text style={styles.text}>{school}</Text>
                  <Text style={styles.text}>{range}</Text>
                </>
              );
            })}
          </View>

          {/* About me */}
          <View style={styles.section}>
            <Text style={styles.subtitleSection}>ABOUT ME</Text>
            <Text style={styles.text}>I am adventurous, I like to travel the world
world, I invest my free time improving
my knowledge. When I can
I play padel, played soccer for
11 years and in the last years attended the gym simultaneously.
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);

const ResumePage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }

      education: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/education/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              school
              range
            }
          }
        }
      }

      projects: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tech
              external
            }
          }
        }
      }

      skills: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/skills/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              programing
              tools
              plataforms
              others
            }
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;
  const educationData = data.education.edges;
  const projectsData = data.projects.edges;
  const skillsData = data.skills.edges;

  const [download, setDownload] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');
    if (mode === 'download') {
      setDownload(true);
    }
  }, []);

  return (
    <>
      <Layout location={location}>
        {download ?
          <PDFDownloadLink document={
            <MyDocument
              jobs={jobsData}
              education={educationData}
              projects={projectsData}
              skills={skillsData}/>
          } fileName="joao_silva_resume.pdf">
            {/* {({ blob, url, loading, error } */}
            {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
          </PDFDownloadLink>
          :
          <PDFViewer style={styles.PDFViewer} showToolbar={false}>
            <MyDocument
              jobs={jobsData}
              education={educationData}
              projects={projectsData}
              skills={skillsData}
            />
          </PDFViewer>
        }
      </Layout>
    </>

  );
};

ResumePage.propTypes = {
  location: PropTypes.object.isRequired,
};

MyDocument.propTypes = {
  jobs: PropTypes.object.isRequired,
  education: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  skills: PropTypes.object.isRequired,
};

export default ResumePage;